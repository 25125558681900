import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import { useStaticQuery, graphql } from "gatsby";

import IndexLayout from "../../layouts";
import Menu from "../../containers/Menu";
import Footer from "../../containers/Footer";
import { BlogHeader, Wrapper } from "../../components/Blog";
import { Title, Container } from "../../containers/Hero/styles";
import Image from "gatsby-image";

import { css } from "@emotion/core";

const ImageStyle = css`
  width: 40vh !important;
  height: 30vh !important;
  display: block !important;
  margin: 5rem auto !important;
`;

export default ({
  pathContext: {
    intl: { originalPath },
  },
}) => {
  const imagesData = useStaticQuery(graphql`
    query {
      imageSharp(fixed: { originalName: { eq: "business-robot-cinema.png" } }) {
        fixed(quality: 100, fit: CONTAIN) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  `);
  const { formatMessage } = useIntl();

  return (
    <IndexLayout>
      <Menu originalPath={originalPath} />
      <Wrapper>
        <BlogHeader />

        <Container>
          <Title>{formatMessage({ id: "blog.be-patient-text" })}</Title>
        </Container>
        <Image fixed={imagesData.imageSharp.fixed} css={ImageStyle} />
      </Wrapper>
      <Footer originalPath={originalPath} />
    </IndexLayout>
  );
};
